<template>
  <Loader :value="promises.fetch">
    <div class="residential-page">
      <div class="residential-page__header section__header">
        <Cover />
        <Summary />
      </div>
      <section class="section section__info">
        <div class="container">
          <div class="grow-1">
            <Openhouse v-if="showOpenhouse" class="mb-6" />
            <Roles />
          </div>
          <Info />
        </div>
      </section>
      <section class="section section__properties">
        <div class="container">
          <ScrollAnchor value="properties" offset="-8rem" :delay="750" />
          <Properties />
        </div>
      </section>
      <section v-if="$path('media.floorplan.length', item)" class="section section__floorplan">
        <div class="container">
          <ScrollAnchor value="floorplan" offset="-8rem" :delay="750" />
          <Floorplan />
        </div>
      </section>
      <section
        v-if="$path('shop.published', item) && !hideShop"
        class="section section__shop section--no-p"
      >
        <div class="container">
          <ScrollAnchor value="shop" offset="-8rem" :delay="750" />
          <ShopIntro />
        </div>
      </section>
      <section class="section section__lead">
        <div class="container">
          <div class="row wrap g-6">
            <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
            <Lead class="grow-1" />
          </div>
        </div>
      </section>
      <section v-if="$path('project.flatfinders.length', item)" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" :delay="750" />
        <Flatfinder />
      </section>
      <section v-if="item.id" class="section section__residentials">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section
        class="section section__attachments"
        v-if="
          $path('media.attachments.length', item) || $path('project.media.attachments.length', item)
        "
      >
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>
      <section
        v-if="$path('media.gallery.length', item)"
        class="section section__images section--no-pb"
      >
        <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
        <Masonry />
      </section>
      <section class="section section__map section--no-p">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <Map />
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ResidentialComponents from '@/components/Residential'
import Residentials from '@/components/Residentials'
import ScrollAnchor from '@kvass/scroll-anchor'
import BrowserApiMixin from '@/mixins/browser-api'

export default {
  mixins: [BrowserApiMixin('residential')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler(val) {
        return this.fetch(val).then(data => {
          if (!data) return this.$router.push({ name: 'notfound' })
        })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('Residential', ['promises', 'item']),
    hideShop() {
      return KvassConfig.get('siteSettings.hideShop')
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
  },
  methods: {
    ...mapActions('Residential', ['fetch']),
    gotoLead() {
      document.querySelector('#lead').scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
  metaInfo() {
    return {
      title: this.item.name || `${this.$t('loading', { resource: null })}...`,
    }
  },
  components: {
    ...ResidentialComponents,
    Residentials,
    ScrollAnchor,
  },
}
</script>

<style lang="scss">
.residential-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  &__header {
    display: flex;

    @include respond-below('phone') {
      flex-direction: column;
    }

    .residential-cover__wrapper,
    .summary {
      flex-basis: 50%;
      flex-grow: 1;

      @include respond-below('phone') {
        flex-basis: initial;
      }
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__shop {
      .container {
        .shop-intro {
          min-height: 450px;
          position: relative;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }
    }

    &__info {
      .container {
        display: grid;
        grid-gap: 6rem;
        grid-template-columns: repeat(2, 1fr);

        @include respond-below('phone') {
          grid-template-columns: 1fr;

          & > *:first-child {
            order: 2;
          }

          & > *:nth-child(2) {
            order: 1;
          }
        }
      }
    }
  }

  .cta {
    background-color: $success;
  }
}
</style>
